import React, { Suspense } from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

// global context provider
import GlobalContextProvider from './contextprovider';

// routes
const Login = React.lazy(() => import('./routes/login'));
const Dashboard = React.lazy(() => import('./routes/dashboard'));

// components
import GlobalLoadingSpinner from './higher-order-components/global-loading-spinner';
import GlobalToastsList from './higher-order-components/global-toasts-list';
import LoadingSpinner from './components/loading-spinner';

import './styles/app.scss';


// define routes content components
const PAGES = [
  {
    path: '/',
    Component: Login,
  },

  {
    path: '/*',
    Component: Dashboard,
  },
];


class App extends React.Component {
  render() {
    // TODO: set appropriately for this project
    // optivar:g: [
    //   'product_basename',
    //   'product_name',
    //   'product_fullname',
    //   'company_name',
    //   'company_website',
    //
    //   'api_msgpack',
    //   'use_webp',
    //   'max_filesize',
    //   'html_output_num_rows',
    //  ]
    return (
      <Router>
        <GlobalContextProvider>
          <Suspense
            fallback={
              <LoadingSpinner
                isVisible={true}
                obscure={true}
              />
            }
          >
            <Switch>
              {
                PAGES.map(
                  ({ path, Component }) => {
                    return (
                      <Route
                        key={path}
                        path={path}
                        exact
                      >
                        {({ match }) => (
                          <CSSTransition
                            in={match !== null}
                            timeout={250}
                            classNames={'app'}
                            unmountOnExit
                          >
                            <div className={'app'}>
                              <Component />
                            </div>
                          </CSSTransition>
                        )}
                      </Route>
                    );
                  }
                )
              }
            </Switch>
          </Suspense>

          <GlobalLoadingSpinner />
          <GlobalToastsList />
        </GlobalContextProvider>
      </Router>
    );
  }
}


render(
  React.createElement(App, null),
  document.body.querySelector('#app')
);
